<template>
  <section class="GalleryEvent" v-if="images">
    <div class="columns" >
      <div class="GalleryEvent-Image1 column is-4-tablet no-padding-right" v-if="typeof images[0] !== 'undefined'">
        <img :src="images[0].url" class="img-fluid img-300-event">
        <div v-if="images[0].titleImg" class="title-img" :style="{color: design.colorSteper, backgroundColor: design.bgColorStepper}">
          {{ images[0].titleImg }}
        </div>
      </div>
      <div class="is-4-tablet column no-padding-right" v-if="typeof images[1] !== 'undefined'">
        <img :src="images[1].url" class="img-fluid img-300-event" style="height: 380px;width: 100%">
        <div v-if="images[1].titleImg" class="title-img" :style="{color: design.colorSteper, backgroundColor: design.bgColorStepper}">
          {{ images[1].titleImg }}
        </div>
      </div>
      <div class="is-4-tablet column no-padding-right" v-if="typeof images[2] !== 'undefined'">
        <img :src="images[2].url" class="img-fluid img-300-event" style="height: 380px;width: 100%">
        <div v-if="images[2].titleImg" class="title-img" :style="{color: design.colorSteper, backgroundColor: design.bgColorStepper}">
          {{ images[2].titleImg }}
        </div>
      </div>
    </div>
    <div class="row top-15-mobile columns" v-if="images">
      <div class="GalleryEvent-Image5 column" :class="{ 'is-7-tablet no-padding-right' : images.length > 4, 'is-7-tablet no-padding-right':  images.length == 4 }" v-if="typeof images[3] !== 'undefined'">
        <img :src="images[3].url" class="img-fluid img-500-event" style="height: 500px;width: 100%">
        <div v-if="images[3].titleImg" class="title-img" :style="{color: design.colorSteper, backgroundColor: design.bgColorStepper}">
          {{ images[3].titleImg }}
        </div>
      </div>
      <div class="is-5-tablet column no-padding-right" v-if="typeof images[4] !== 'undefined'">
        <img :src="images[4].url" class="img-fluid img-500-event" style="height: 500px;width: 100%">
        <div v-if="images[4].titleImg" class="title-img" :style="{color: design.colorSteper, backgroundColor: design.bgColorStepper}">
          {{ images[4].titleImg }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'GalleryEvent',
  props: ['images']
};
</script>
<style lang="scss" scoped>
.GalleryEvent {
  padding: 15px 0px;
  &-Image1 {
    @media (min-width: 768px) {
      padding-left: unset;
    }
  }
  &-Image5 {
    @media (min-width: 768px) {
      padding-left: unset;
    }
  }
}

img {
  object-fit: cover;
}
.img-300-event {
  height: 380px;
  width: 100%;
}
.img-500-event {
  height: 380px;
  width: 100%;
}
.radius-3 {
  border-radius: 3px;
}
.margin-row-10 {
  margin-right: 10px;
  margin-left: 10px;
}
.errors {
  color: red;
}
.f26 {
  font-size: 26px;
}
.w-80 {
  width: 80px !important;
}
.w-20 {
  width: 20px !important;
}
@media (min-width: 960px) {
  .no-padding-right {
    padding-right: 0px;
  }

  .top-15-mobile {
    margin-top: 15px;
  }
}
@media (max-width: 960px) {
  .img-300-event {
    height: auto !important;
    width: 380px !important;
    padding: 5px;
    display: block;
    margin: auto;
  }
  .img-500-event {
    height: auto !important;
    width: 380px !important;
    padding: 5px;
    display: block;
    margin: auto;
  }
}
.title-img {
  width: 100% !important;
  margin-top: -44px;
  position: inherit;
  padding: 12px 10px;
}
</style>
