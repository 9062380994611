<template>
  <section
    :style="[vendor.bannerURL ? { backgroundImage: 'url(' + vendor.bannerURL + ')' } : '']"
    class="col-12 Eventos"
    v-if="designEvent && event"
  >
    <NavBar :isButton="true" @validate-user="hello" back="home"></NavBar>
    <HeaderEvent :bannerURL="event.coverURL" :name="event.displayName"></HeaderEvent>
    <Selection :info="event" :letModl="recibeModal"></Selection>
    <div class="container">
      <InfoEvent :designparams="designEvent" :info="event"></InfoEvent>
      <GalleryEvent :images="event.images" :info="event"></GalleryEvent>
    </div>
    <FooterEvent :designparams="designEvent"></FooterEvent>
  </section>
</template>
<script>
import { mapGetters } from 'vuex';
import globalTypes from '@/store/types/global';
import whiteLabelTypes from '@/store/types/whiteLabel';
import eventTypes from '@/store/types/event';
import HeaderEvent from '@/components/Event/header';
import Selection from '@/components/Event/selection';
import InfoEvent from '@/components/Event/infoEvent';
import GalleryEvent from '@/components/Event/gallery';
import FooterEvent from '@/components/Event/footer';
import NavBar from '@/components/Select/navBar';

export default {
  name: 'EventId',
  components: {
    HeaderEvent,
    Selection,
    InfoEvent,
    GalleryEvent,
    FooterEvent,
    NavBar
  },
  data: () => ({
    eventId: null,
    recibeModal: ''
  }),
  computed: {
    ...mapGetters({
      event: [eventTypes.getters.event],
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    })
  },
  methods: {
    hello (varle) {
      this.recibeModal = varle;
    }
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    this.eventId = this.$route.params.id ? this.$route.params.id : 0;
    const vendorUrl = this.subDomain();
    this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
    this.$store
      .dispatch({ type: 'whiteLabel:vendorId', data: { url: vendorUrl } })
      .then(() => {
        if (this.$store.getters['whiteLabel:vendorId']) {
          this.$store
            .dispatch({
              type: 'whiteLabel:vendor',
              data: { vendorId: this.$store.getters['whiteLabel:vendorId'] }
            })
            .then(data => {
              document.title = data.data.vendor.displayName
                ? data.data.vendor.displayName
                : '';
              document.querySelector("link[rel*='icon']").href =
                (data.data.vendor.photoURL !== null) ? data.data.vendor.photoURL : data.data.vendor.thumbnailURL;
            });
          this.$store.dispatch({
            type: 'event:event',
            data: { id: this.eventId }
          });
        }
      });
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  }
};
</script>
